const ADMIN_USERS = [
    "umrashrf@gmail.com",
    "umr.ashrf@gmail.com",
    "umair@umairgroup.com",
];

const DEBT_ACCOUNT_TYPES = [
    'Credit',
    'Credit Card',
    'Loan',
];

module.exports = {
    ADMIN_USERS,
    DEBT_ACCOUNT_TYPES,
};
