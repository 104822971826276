import { useEffect, useState } from "react";

function UnsubscribeScreen() {
    const [status, setStatus] = useState('Success');

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const email = params.get('email');
        if (email) {
            fetch(process.env.REACT_APP_CANCELLY_API_URL + '/email/unsubscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 'ok') {
                        setStatus('Success!');
                    } else {
                        setStatus('Uh oh, something went wrong');
                    }
                });
        } else {
            setStatus('Uh oh, email wasn\'t found');
        }
    }, []);

    return (
        <div className="App-Component component-wrapper">
            <div className="mt-8">
                <h2 className="text-2xl">{status}</h2>
                <p className="mt-5">We are sad to let you go</p>
                <p className="mt-5">Please send us an email to <b>umair@cancelly.ca</b> on how can we improve our service.</p>
            </div>
        </div>
    );
}

export default UnsubscribeScreen;
