const moment = require('moment');

export default function getSpecialDate() {
    const today = moment(); // Use local time zone
    const currentDay = today.date();
    const currentMonth = today.month();
    const currentYear = today.year();
    const currentDayOfWeek = today.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    let specialDate = null;

    if (currentDay < 14) {
        specialDate = moment([currentYear, currentMonth, 14]); // Use local time zone
    } else if (currentDay < 29) {
        specialDate = moment([currentYear, currentMonth, 29]); // Use local time zone
    } else {
        specialDate = moment([currentYear, currentMonth + 1, 14]); // Use local time zone
    }

    if (currentDayOfWeek === 6) { // Saturday
        specialDate.subtract(1, 'day');
    } else if (currentDayOfWeek === 0) { // Sunday
        specialDate.add(1, 'day');
    }

    return specialDate ? specialDate.format('MMMM DD, YYYY') : '';
}
