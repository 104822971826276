import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function BankLoginScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [bank, setBank] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (location.state) {
            setBank(location.state.bank);
        }
    }, [location, location.state]);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, authUser => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoader(true);

        const token = await user.getIdToken();

        fetch(process.env.REACT_APP_CANCELLY_API_URL + '/bank/login', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ bank, username, password })
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'ok') {
                    navigate('/bank/login2fa', {
                        state: {
                            bank,
                            username,
                            password,
                            accessToken: res.access_token,
                        }
                    });
                }
                setLoader(false);
            })
            .catch(err => {
                setLoader(false);
                console.log(err);
            });

        return false;
    }

    return (
        <div>
            <h1 className="text-white text-3xl my-10">Connect Your Bank Account</h1>
            <div className="relative bg-slate-100 rounded m-16 p-10 flex items-center justify-center">
                {loader && <Loader />}
                <form onSubmit={handleSubmit} className="flex justify-center" disabled={loader}>
                    <fieldset className="flex flex-col w-max text-2xl justify">
                        {bank && <>
                            <img src={bank.icon} alt={bank.name} className="max-w-xs self-center" />
                            <h2 className="mt-4">Log in to {bank.name} online banking</h2>
                        </>}
                        <input onChange={(e) => setUsername(e.currentTarget.value)}
                            name="username"
                            type="username"
                            placeholder="Username"
                            className="p-2 my-3" />
                        <input onChange={(e) => setPassword(e.currentTarget.value)}
                            name="password"
                            type="password"
                            placeholder="Password"
                            className="p-2 my-3" />
                        <button type="submit"
                            value="Login"
                            className="bg-slate-200 text-black p-2 my-3">Login</button>
                        <p className="text-sm my-6 max-w-sm text-slate-800">
                            Encrypted in transit with TLS and with Google supplied AES-256-bit encryption.
                            <br /><br />
                            <a href="https://cloud.google.com/secret-manager"
                                rel="noreferrer"
                                target="_blank"
                                className="hover:text-slate-500 hover:underline">Click here to read more about Google Secret Manager</a>
                        </p>
                    </fieldset>
                </form>
            </div>
        </div>
    );
}
