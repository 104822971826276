const moment = require('moment-timezone');

export default function describeFutureDate(targetDate) {
    const targetMonth = targetDate.format('MMM');
    const targetDay = targetDate.format('DD');
    const targetHour = targetDate.format('H');
    const targetMinute = targetDate.format('mm');

    const currentDate = moment();
    const duration = moment.duration(targetDate.diff(currentDate));

    if (duration.asMilliseconds() < 0) {
        return `past due, ${targetDay} ${targetMonth} @ ${targetHour}h:${targetMinute}m`;
    }

    if (duration.asMilliseconds() < 1000) {
        return `just now, ${targetDay} ${targetMonth} @ ${targetHour}h:${targetMinute}m`;
    }

    if (duration.asMilliseconds() < 60000) {
        const seconds = Math.floor(duration.asSeconds());
        return `in ${seconds} second${seconds !== 1 ? 's' : ''}, ${targetDay} ${targetMonth} @ ${targetHour}h:${targetMinute}m`;
    }

    if (duration.asMilliseconds() < 3600000) {
        const minutes = Math.floor(duration.asMinutes());
        return `in ${minutes} minute${minutes !== 1 ? 's' : ''}, ${targetDay} ${targetMonth} @ ${targetHour}h:${targetMinute}m`;
    }

    if (duration.asMilliseconds() < 86400000) {
        const hours = Math.floor(duration.asHours());
        return `in ${hours} hour${hours !== 1 ? 's' : ''}, ${targetDay} ${targetMonth} @ ${targetHour}h:${targetMinute}m`;
    }

    if (duration.asMilliseconds() < 604800000) {
        const days = Math.floor(duration.asDays());
        if (days === 1) {
            return `tomorrow, ${targetDay} ${targetMonth}`;
        }
        return `in ${days} day${days !== 1 ? 's' : ''}, ${targetDay} ${targetMonth}`;
    }

    if (duration.asMilliseconds() < 2628000000) {
        const weeks = Math.floor(duration.asWeeks());
        return `in ${weeks} week${weeks !== 1 ? 's' : ''}, ${targetDay} ${targetMonth}`;
    }

    if (duration.asMilliseconds() < 31536000000) {
        const months = Math.floor(duration.asMonths());
        return `in ${months} month${months !== 1 ? 's' : ''}, ${targetDay} ${targetMonth}`;
    }

    const years = Math.floor(duration.asYears());
    return `in ${years} year${years !== 1 ? 's' : ''}, ${targetDay} ${targetMonth}`;
}
