import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//import { FaLock } from 'react-icons/fa';
//import moment from 'moment';
import firebaseConfig from './firebase_config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { ADMIN_USERS } from './lib/constants';

function BankDisconnectScreen() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [showCancelButton, setShowCancelButton] = useState(true);
    const [feedback, setFeedback] = useState("");
    const [deleteData, setDeleteData] = useState(false);

    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }

    // const db = firebase.firestore();

    const disconnectBank = async (event) => {
        event.preventDefault();
        const currentUser = firebase.auth().currentUser;

        if (currentUser) {
            (async () => {
                const token = await currentUser.getIdToken();

                fetch(process.env.REACT_APP_CANCELLY_API_URL + "/plaid/disconnect", {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: currentUser.email,
                        itemId: state.itemId,
                        feedback,
                        deleteData,
                    })
                })
                    .then(res => res.json())
                    .then(res => {
                        setLoading(false);
                        if (res.status === "ok") {
                            alert("Bank Deleted");
                            navigate('/dashboard');
                        } else {
                            alert("Error deleting bank");
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        alert("Error deleting bank");
                    });
            })();
        }
        return false;
    }

    return (
        <>
            {loading
                ? <img className="self-center inline" src="loader.gif" alt="Loading..." width="100" />
                : <div className="text-white m-5">
                    <h1 className="text-3xl">Disconnect Bank</h1>
                    <form className="mt-10" onSubmit={disconnectBank}>
                        <div>
                            {state && state.name &&
                                <ol className="ml-4 mb-4">
                                    <li>{state.name}</li>
                                </ol>}
                        </div>
                        {showCancelButton
                            ? <div>
                                <textarea name="feedback"
                                    defaultValue={feedback}
                                    className="w-full min-h-[200px] p-2 text-black"
                                    placeholder="Please give us your feedback"
                                    onChange={(e) => setFeedback(e.target.value)}></textarea>
                                {/* <div className="text-left flex items-center text-xl mt-4 mb-4">
                                    <input name="deletedata"
                                        type="checkbox"
                                        className="mr-1 w-10 h-10"
                                        defaultChecked={deleteData}
                                        onClick={() => setDeleteData(!deleteData)} />
                                    <label for="deletedata" className="ml-1">Also delete all previous data stored from this bank</label>
                                </div> */}
                                <button type="submit" className="mt-4 p-4 bg-red-600 block w-full" href="https://billing.stripe.com/p/login/14keWT3Iu4ui3rW7ss">Disconnect</button>
                                <p className="mt-1"><b>Important Note: </b>This action is non reversible.</p>
                            </div>
                            : <div>
                                <button disabled className="p-4 bg-gray-600 block w-full">Disconnect</button>
                                <p className="mt-1 text-red-600">No bank found</p>
                            </div>
                        }
                    </form>
                </div>
            }</>
    );
}

export default BankDisconnectScreen;
