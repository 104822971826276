function ServicesScreen() {
    return (
        <div className="App-Component component-wrapper w-[600px] lg:w-full m-auto">
            <h1 className="text-2xl mb-4">Services</h1>
            <h3 className="text-xl mb-4">"Cancelly is more than a subscriptions, trials and bank fees tracker..."</h3>
            <div className="text-justify">
                I have helped small businesses manage their Payroll with an <a href="https://www.fiverr.com/umrashrf/share-quebec-payroll-spreadsheet-for-self-employed" className="underline">Automated Payroll Excel Sheet</a> in Quebec, Ontario, Alberta and BC. I have also helped people file their <a href="https://www.fiverr.com/umrashrf/file-a-t4-based-personal-tax-with-canada-revenue-agency-canada-only" className="underline">Canadian Taxes (T1)</a> with a small fee. Looking for 
            </div>
        </div>
    );
}

export default ServicesScreen;
