import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebaseConfig from '../firebase_config';

export default function SearchScreen() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [visibleItems, setVisibleItems] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');

    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, authUser => {
            if (authUser) {
                setUser(authUser);
                fetchItems(authUser);
            } else {
                setUser(null);
                setItems([]);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchItems = async (user) => {
        const collectionRef = firebase.firestore().collection('banks');

        collectionRef.get()
            .then(querySnapshot => {
                const data = [];
                querySnapshot.forEach(doc => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                setItems(data);
            })
            .catch(error => {
                console.error('Error fetching documents: ', error);
            });
    };

    useEffect(() => {
        const filteredItems = items.filter(item =>
            item.enabled && item.name.toLowerCase().includes(searchKeyword.toLowerCase())
        );
        setFilteredItems(filteredItems);
        setVisibleItems(filteredItems.slice(0, 5));
    }, [items, searchKeyword]);

    const handleSearchChange = event => {
        setSearchKeyword(event.target.value);
    };

    const handleClick = item => {
        navigate('/bank/login', {
            state: {
                bank: item,
            }
        });
    };

    const handleOther = event => {
        navigate('/connect');
    };

    return (
        <div className="mx-12 my-8">
            <h1 className="text-white text-3xl my-10">Connect Your Bank Account</h1>
            <div className="flex flex-col bg-white text-black rounded p-2">
                <input
                    type="text"
                    placeholder="Type here to find your bank by name..."
                    value={searchKeyword}
                    onChange={handleSearchChange}
                    className="p-2 shadow rounded"
                />
                <ul className="text-left">
                    {visibleItems.map((item, index) => (
                        <li key={index}
                            className="p-2 hover:bg-slate-200 text-3xl flex flex-row leading-[75px]"
                            onClick={() => handleClick(item)}>
                            <img src={item.icon} alt={item.name} className="rounded-full h-[4.8rem] w-[4.8rem] mr-4" />{item.name}
                        </li>
                    ))}
                    <li className="p-2 hover:bg-slate-200 text-3xl flex flex-row leading-[75px]" onClick={handleOther}>
                        <img src="assets/web/bank/other_bank.png" alt="" className="rounded-full h-[4.8rem] w-[4.8rem] mr-4" />Other
                    </li>
                </ul>
                {filteredItems.length > 5 && (
                    <p className="p-2 text-left">{`+ ${filteredItems.length - 5} more items match the search.`}</p>
                )}
            </div>
        </div>
    );
}
