import './PayrollScreen.css';

function PayrollScreen() {
    return (
        <div className="App-Component component-wrapper w-full m-auto">
            <h1 className="text-2xl mb-4 down w-[600px] m-auto lg:w-full">
                Automated Payroll For Self-Employed & Small Business
            </h1>
            <h3 className="text-xl mb-4">Free For Members</h3>
            <div className="">
                <iframe src="https://player.vimeo.com/video/1000961155?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    title="Automated Payroll For Self-Employed & Small Business"
                    className="w-full lg:w-[300px] lg:m-auto h-[625px] lg:h-full"></iframe>
                <p className="mt-1 text-sm invisible lg:visible">Best viewed on desktop or in landscape</p>
            </div>
            <div>

            </div>
        </div>
    );
}

export default PayrollScreen;
