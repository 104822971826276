import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from './firebase_config';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import Loader from './Loader';
import reportWebVitals from './reportWebVitals';
import GlobalEventLogger from './GlobalEventLogger';

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <Router>
        <GlobalEventLogger>
          <App />
        </GlobalEventLogger>
      </Router>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
