import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getAuth,
    sendSignInLinkToEmail,
    GoogleAuthProvider,
    signInWithPopup,
} from "firebase/auth";
import './tailwind.css';
import './Home.css';
// import PlaidIframeComponent from './PlaidIframeComponent';

function Home() {
    const navigate = useNavigate();
    const [activeComponent, setActiveComponent] = useState('Home');

    // var initHasAccount = false;

    // const loginId = localStorage.getItem("flinks_loginId");
    // if (loginId !== undefined && loginId != null) {
    //   initHasAccount = true;
    //   setActiveComponent("Component4");
    // } else {
    //   initHasAccount = false;
    //   setActiveComponent("Component1");
    // }

    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
    const [maxPlaidItems, setMaxPlaidItems] = useState(100);
    const [availablePlaidItems, setAvailablePlaidItems] = useState(80);
    // const [hasAccount, setHasAccount] = useState(initHasAccount);
    // const [submitCount, setSubmitCount] = useState(0);

    // const onMessageReceived = (e) => {
    //   if (e.data.step === 'REDIRECT' && 'loginId' in e.data && e.data.loginId !== undefined && submitCount === 0) {
    //     window.removeEventListener("message", onMessageReceived);

    //     localStorage.setItem("flinks_loginId", e.data.loginId);

    //     if (email !== null && email.length > 0 && e.data.loginId !== null && e.data.loginId.length > 0) {
    //       // setHasAccount(true);
    //       setSubmitCount(submitCount + 1);
    //       setActiveComponent("Component4");

    //       const formData = {
    //         email: email,
    //         loginId: e.data.loginId,
    //       };

    //       // Sending the form data to Firebase Functions
    //       fetch(process.env.REACT_APP_CANCELLY_API_URL + '/submit', {
    //         method: 'POST',
    //         headers: { "Content-type": "application/json" },
    //         body: JSON.stringify(formData)
    //       })
    //         .then((response) => response.json())
    //         .then((data) => {
    //           // Handle response from Firebase Functions
    //           console.log(data);
    //         })
    //         .catch((error) => {
    //           // Handle error
    //           console.error(error);
    //         });
    //     }

    //     // setHasAccount(true);
    //     setActiveComponent("Component4");
    //   }
    // };

    // window.addEventListener("message", onMessageReceived, true);

    // const [isLoadingIFrame, setIsLoadingIFrame] = useState(!initHasAccount);

    // const handleIframeLoad = () => {
    //   setIsLoadingIFrame(false);
    // };

    // useEffect(() => {
    //     fetch(process.env.REACT_APP_CANCELLY_API_URL + '/plaid/usage', {
    //         method: 'POST',
    //         headers: {
    //             "Content-type": "application/json",
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((res) => {
    //             if (res.status === "ok" && res.count > 80 && res.count <= 100) {
    //                 setAvailablePlaidItems(res.count);
    //             }
    //         })
    //         .catch((error) => {
    //             // Handle error
    //             console.error(error);
    //         });
    // }, []);

    const handleEmailChange = (event) => {
        const enteredEmail = event.target.value;
        setIsEmailValid(validateEmail(enteredEmail));
        setEmail(enteredEmail);
    };

    const validateEmail = (email) => {
        // Basic email validation using a regular expression
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const saveStats = () => {
        const formData = {
            email: email.toLocaleLowerCase(),
            computer_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            browser_notifications_permission: localStorage.getItem("notifications_permission") || "denied",
        };

        // Sending the form data to Firebase Functions
        fetch(process.env.REACT_APP_CANCELLY_API_URL + '/save_email', {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(formData)
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle response from Firebase Functions
                // console.log(data);
            })
            .catch((error) => {
                // Handle error
                console.error(error);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!isEmailSubmitted && email !== null && email.length > 0) {
            console.log('Submitting...');

            saveStats();
            localStorage.setItem("email", email);

            const actionCodeSettings = {
                url: window.location.href + 'verify',
                handleCodeInApp: true,
            };

            const auth = getAuth();
            sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                    navigate('/verify');
                })
                .catch((error) => {
                    const errorMsg = error.toString().replace(/firebase/gi, '');
                    alert(errorMsg);
                    //navigate("/404");
                });

            setIsEmailSubmitted(true);

            // childRef.current.handlePlaidLink();

            // navigate('/connect');
            // setActiveComponent('Plaid');

            // setTimeout(function () {
            //   scrollToIframe();
            // }, 500);
        }
    };

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');

        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...
                setEmail(user.email);
                saveStats();
                localStorage.setItem("email", user.email);
                navigate('/dashboard');

            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                const errorMsg = error.toString().replace(/firebase/gi, '');
                alert(errorMsg);
            });
    };

    // const scrollToIframe = () => {
    //     const container = document.getElementById('plaid-container');
    //     const iframe = container.querySelector('iframe');
    //     if (iframe) {
    //         console.log(iframe);
    //         const duration = 5;
    //         const { x, y } = iframe.getBoundingClientRect();

    //         console.log('x, y', x, y);

    //         const scrollStep = Math.PI / (duration / 15);
    //         let count = 0;
    //         let curPosition = {
    //             x: window.scrollX,
    //             y: window.scrollY
    //         };

    //         let animationRunning = true;

    //         const scrollAnimation = () => {
    //             if (!animationRunning) return;

    //             if (window.scrollX !== x || window.scrollY !== y) {
    //                 window.scrollTo(curPosition.x + ((x - curPosition.x) / duration) * count,
    //                     curPosition.y + ((y - curPosition.y) / duration) * count);
    //                 count += 1;
    //                 setTimeout(scrollAnimation, 50);
    //             } else {
    //                 animationRunning = false;
    //             }
    //         };

    //         scrollAnimation();
    //     }
    // };

    // const [shouldShowFileUploader, setShouldShowFileUploader] = useState(false);

    const showFileUploader = (event) => {
        event.preventDefault();
        // setShouldShowFileUploader(true);
        console.log(email);
        navigate('/csv');
    };

    const handlePlaidFinish = (publicToken) => {
        navigate('/thanks');
    };

    return (
        <div>
            <div className={`App-Component component-wrapper ${activeComponent !== 'Home' ? 'hidden' : ''}`}>
                <div class="flex flex-row lg:flex-col">
                    <div class="w-2/5 lg:w-full">
                        <div className="flex h-10 text-sm text-black bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700 dark:text-white mb-2" role="progressbar" aria-valuenow={0} aria-valuemin="0" aria-valuemax={100}>
                            <div
                                className="flex flex-col justify-center rounded-full overflow-hidden bg-red-600 text-white text-center whitespace-nowrap transition duration-500 dark:bg-red-500 p-2"
                                style={{ width: '100%' }}>
                                <p>Plaid development connections will be deleted soon <a href="https://plaid.com/docs/quickstart/glossary/#development" className="underline" target="_blank" rel="noreferrer">read more here</a></p>
                            </div>
                            {/* <div className="self-center ml-1">Left ({maxPlaidItems - availablePlaidItems}%)</div> */}
                        </div>
                        <form onSubmit={handleSubmit} className="Form p-5 mt-5 border-solid border-black">
                            <div className="block">
                                <h1 className="Form-Header text-4xl font-bold">
                                    Cut costs each <br />month or trim<br /><span className="Bold">over time</span>.
                                </h1>
                                <div className="Form-Placeholder">
                                    <label htmlFor="email">All we need is your email address</label>
                                </div>
                                <br />
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder="Email Address"
                                    className="Form-Input border-solid placeholder:text-gray-500 border-[1px] border-gray-500 w-[340px] lg:w-[300px] p-[7px]"
                                    required
                                />
                                <br />
                                <div className='mt-5 flex'>
                                    <button type="submit" className="Form-Button xs:text-xs text-sm rounded-none text-white bg-black mt-0 p-0" disabled={!isEmailValid}>
                                        Calculate Savings</button>
                                    <button type="button" className="xs:text-xs text-sm ml-2 h-[50px] rounded-non border-gray border-solid border-2" onClick={signInWithGoogle}>
                                        <div className="flex p-2">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="w-4 LgbsSe-Bz112c mr-2"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg><p>Sign in with Google</p>
                                        </div>
                                    </button>
                                </div>
                                <p className="Form-Tooltip">Try it FREE: No Credit Card Required.</p>
                            </div>
                        </form>
                        <ol className="Notes list-decimal">
                            <li>
                                <div className='question'>What is Cancelly?</div>
                                <div>Cancelly is an app to analyze your banking transactions for recurring subscriptions, bank fees, and interest charges. It can consolidate your debt, file taxes, and come with a free Chrome Extension for tracking trial sign-ups to avoid unintentional charges.</div>
                            </li>
                            <li>
                                <div className='question'>How does Cancelly work?</div>
                                <div>After putting your email address and connecting your bank accounts with <b>trusted third party</b> (Flinks or Plaid), our Machine Learning pipeline will analyze your transactions for fraudulent recurring subscriptions and send you a detailed email.</div>
                            </li>
                            <li>
                                <div className='question'>Can a business/corporation use Cancelly?</div>
                                <div><b>Absolutely!</b> Cancelly is a business first solution for your banking transactions. As a business ourself we feel the pain with fraudulent recurring subscriptions.</div>
                            </li>
                            <li>
                                <div className='question'>Do you have access to or do you store my bank username and password?</div>
                                <div><b>We do not</b> and can not see your online banking username or password. The third party takes care of that for us and we simply get your transactions to feed to our Machine Learning.</div>
                            </li>
                        </ol>
                    </div>
                    <div className="w-3/5 lg:w-full">
                        <div className="ml-12 -mt-16 -mr-10 lg:ml-0 lg:mt-2 lg:mr-0 flex flex-rows">
                            <div className="">
                                <img
                                    src="./assets/web/cancelly_subscriptions_demo1.png"
                                    alt="Cancelly Subscriptions Demo"
                                    className="opacity-60" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`App-Component component-wrapper ${activeComponent !== 'Plaid' ? 'hidden' : ''}`}>
                <div className="App-Component component-wrapper">
                    <div>
                        <h2>Lastly we need your bank account(s)</h2>
                        <p>Or use a csv file uploader <button onClick={showFileUploader}>click here</button></p>
                        {/* <iframe className="flinksconnect" height="760" title="flinks"
              src="https://toolbox-iframe.private.fin.ag/?demo=true&redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true"></iframe> */}
                        {/* <PlaidIntegration ref={childRef} onFinish={() => { setActiveComponent("Component4") }} /> */}
                        {/* <PlaidIframeComponent email={email} onFinish={handlePlaidFinish} /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
