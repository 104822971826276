function NotFound() {
    return (
        <div className="App-Component component-wrapper">
            <h1 className="text-2xl mb-4">404 Not Found</h1>
            <h3 className="text-xl mb-4">Are you trying to access a private page? Please login in first.</h3>
            <div className="text-center">
                <a href="/" className="border-solid border-gray border-[1px] py-2 px-8 bg-black">Visit Homepage</a>
            </div>
        </div>
    );
}

export default NotFound;
