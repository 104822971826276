function StoryScreen() {
    return (
        <div className="App-Component component-wrapper w-[600px] lg:w-full m-auto">
            <h1 className="text-2xl mb-4">Our Story</h1>
            <h3 className="text-xl mb-4">"A long time ago in a galaxy far, far away…”</h3>
            <div className="text-justify">
                In June 2023 I was visiting family in Edmonton AB during the end of COVID-19 when everybody started flying after 2 years of Covid. I had accumulated so much credit card and other debt that it got out of hand. Using credit cards is a hazardous thing because you tend to overspend. I remember during my flight once a lady next to me gave me her magazine to read and I read an article which said that spending paper money is better than plastic money because you tend to spend less just because it gives you a feeling of a loss when it moves hand. I knew it subconsciously but that article revalidated that. Now using credit cards on the internet for personal and especially business use is a lot more problematic than it is at local shops and restaurants. I have heard from friends and family countless times how they signed up for an online trial just to forget it and see a $200 charge they weren't expecting on their statement. Many credit cards don't even alert you for charges. This problem also applies to debit and prepaid cards. And what about the monthly/annual fees charged by those credit cards? And what about the interest you are charged each day/month or each year? ChatGPT and other AI tools are not there yet to solve these problems on their own. An intelligent software ecosystem for debt management, cost-cutting and to grow savings is needed and hence Cancelly was born on June 08, 2023. Before Cancelly, I worked at companies like Société Générale Capital Canada, Morgan Stanley, Royal Bank of Canada, and Mylo Financial Technologies and have gained over 13 years of professional work experience in Technology, Engineering, Product Development, Startups and Sales.
            </div>
        </div>
    );
}

export default StoryScreen;
