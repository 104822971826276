import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PlaidIframeComponent from './PlaidIframeComponent';
import firebaseConfig from './firebase_config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
    getAuth,
    onAuthStateChanged,
} from "firebase/auth";

function PlaidScreen(props) {
    const navigate = useNavigate();
    const [plaidError, setPlaidError] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [institutions, setInstitutions] = useState([]);
    const email = localStorage.getItem('email');

    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }

    const showFileUploader = (event) => {
        event.preventDefault();
        // setShouldShowFileUploader(true);
        console.log(email);
        navigate('/csv');
    };

    const handlePlaidFinish = (publicToken) => {
        navigate('/thanks');
    };

    const handlePlaidError = (error) => {
        console.log('handlePlaidError', error);
        setPlaidError(error.error_message);
    };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {

            } else {

            }
        });
    }, []);

    const getInstitutions = async (value) => {
        const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
        fetch(process.env.REACT_APP_CANCELLY_API_URL + "/plaid/institutions", {
            headers: {
                'Authorization': `Bearer ${idTokenResult.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(async res => {
                if (res.status == 'ok') {
                    setInstitutions(res.institutions);
                } else {
                    console.error(res.error);
                    setInstitutions([]);
                }
            })
            .catch(err => {
                console.error('Error occurred', err);
                setInstitutions([]);
            });
    };

    useEffect(() => {
        if (!searchValue && institutions.length <= 0) {
            getInstitutions();
        }
    }, []);

    useEffect(() => {
        const searchInstitutions = async (value) => {
            const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
            fetch(process.env.REACT_APP_CANCELLY_API_URL + "/plaid/search", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${idTokenResult.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    q: value,
                })
            })
                .then(res => res.json())
                .then(async res => {
                    if (res.status == 'ok') {
                        setInstitutions(res.institutions);
                    } else {
                        console.error(res.error);
                        setInstitutions([]);
                    }
                })
                .catch(err => {
                    console.error('Error occurred', err);
                    setInstitutions([]);
                });
        };

        if (searchValue && searchValue.length > 0) {
            searchInstitutions(searchValue);
        } else {
            getInstitutions();
        }
    }, [searchValue]);

    const launchPlaid = async (institution) => {
        setInstitutions([]);
        setSearchValue('');

        if (institution.type === 'plaid') {
            const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
            // Make a request to your Firebase Function to generate a Plaid Link token
            const response2 = await fetch(process.env.REACT_APP_CANCELLY_API_URL + '/generate_link_token', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${idTokenResult.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    institution_id: institution.institution_id,
                }),
            });
            const { linkToken, environment } = await response2.json();
            if (linkToken) {
                console.log('linkToken', linkToken);
                //setLinkToken(data.linkToken);
                //setEnvironment(data.environment);

                const handler = window.Plaid.create({
                    token: linkToken,
                    onSuccess: async function (publicToken, metadata) {
                        const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
                        // Make a request to your Firebase Function to exchange the public token for an access token
                        fetch(process.env.REACT_APP_CANCELLY_API_URL + '/exchange_public_token', {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${idTokenResult.token}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ linkToken, publicToken, environment }),
                        })
                            .then((response) => response.json())
                            .then(({ accessToken }) => {
                                // Handle the access token received from the backend
                                //console.log('Access Token:', data.accessToken);
                                window.location = '/';
                            })
                            .catch((error) => {
                                console.error('Error exchanging public token:', error);
                                alert('Connection Error. Please try again later.');
                            });
                    },
                    onExit: function (err, metadata) {
                        getInstitutions();
                    },
                });

                handler.open();
            }
        } else if (institution.type === 'cancelly_extension') {
            const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
            try {
                const sender = {
                    action: 'institution_connect',
                    body: {
                        token: idTokenResult.token,
                        institution,
                    }
                };
                if (chrome.runtime && chrome.runtime.hasOwnProperty('sendMessage')) {
                    chrome.runtime.sendMessage("bdehkmgfjppeagefkipigfmlnginebkd", sender); /* global chrome */
                    chrome.runtime.sendMessage("jlmgalhiiblhpgkemdoajofnbmhkoplg", sender); /* global chrome */
                } else {
                    console.error('chrome.runtime.sendMessage does not exists');
                    alert('Only works on Desktop with Cancelly Chrome Extension Version 1.4.2 or Above');
                }
            } catch (err) {
                console.log('Error sending message to chrome extension', err);
                alert('Only works on Desktop with Cancelly Chrome Extension Version 1.4.2 or Above');
            }
        }
    };

    return (
        <div className="App-Component component-wrapper w-[600px] lg:w-full m-auto">
            {plaidError ? <div>
                <h1 className="text-2xl mt-2 mb-4">Uh oh</h1>
                <h3 className="text-xl mb-4">Internal Error Occurred</h3>
                <p>
                    <img alt="Error" src="https://source.unsplash.com/random/500x300/?error" />
                </p>
            </div> :
                <div className="mx-4">
                    <form className="mb-4">
                        <h2 className="mt-2 text-2xl">
                            Find your bank or institution
                        </h2>
                        <input name="search" value={searchValue} onChange={e => setSearchValue(e.target.value)}
                            className="mt-2 w-full p-2 text-black"
                            placeholder="For example, Bank of America, TD, etc" />
                    </form>
                    <div className="text-left">
                        <ul className="border-solid border-1 border-slate-900 bg-white text-slate-600">
                            {institutions.map(inst => <li className="border-solid border-1 border-slate-900 hover:bg-slate-400 hover:text-white">
                                <a href="#" onClick={() => launchPlaid(inst)}
                                    className="flex items-center text-lg font-bold block p-5 select-none">
                                    <img src={
                                        inst.logo ? `data:image/jpeg;charset=utf-8;base64,${inst.logo}`
                                            : `https://icon.cancelly.ca/icon?size=80..120..200&url=${inst.url}`}
                                        alt={inst.name}
                                        className="w-[32px] h-[32px]" />
                                    <p className="ml-2">
                                        {inst.name}
                                    </p>
                                </a>
                            </li>)}
                        </ul>
                    </div>
                </div>
            }
        </div>
    );
}

export default PlaidScreen;
