// GlobalEventLogger.js

import React, { useEffect } from 'react';

function logEventToServer(eventName, eventData) {
    const isAdmin = window.localStorage.getItem("admin") || false;
    if (isAdmin) {
        return; // don't log if it's admin
    }

    const logData = {
        eventName: eventName,
        eventData: eventData,
        timestamp: new Date().toISOString(),
    };

    const email = window.localStorage.getItem('email');
    if (email) {
        logData['email'] = email;
    }

    fetch(process.env.REACT_APP_CANCELLY_API_URL + '/log/user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(logData),
    })
        .then((response) => {
            if (!response.ok) {
                console.error('Failed to send log data to the server');
            }
        })
        .catch((error) => {
            console.error('Error while sending log data:', error);
        });
}

// Example of logging a mouse event
function handleMouseEvent(event) {
    const eventName = event.type;
    const eventData = {
        x: event.clientX,
        y: event.clientY,
        documentWidth: document.documentElement.clientWidth,
        documentHeight: document.documentElement.clientHeight,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        screenResolution: window.devicePixelRatio,
    };
    logEventToServer(eventName, eventData);
}

// Example of logging a link event
function handleLinkEvent(event, eventName) {
    if (event.target.tagName === 'A') {
        const eventData = {
            linkText: event.target.textContent,
            linkURL: event.target.href,
        };
        logEventToServer(eventName, eventData);
    }
}

// Function to get UTC date and time as a string
function getUTCTime() {
    const now = new Date();
    return now.toISOString();
}

// Function to log the mouse cursor coordinates and event details
function logMouseEvents(event, usernameOrEmail) {
    const x = event.clientX;
    const y = event.clientY;
    const timestamp = getUTCTime();

    // Log mouse coordinates, event name, and event details to the console
    console.log(`${timestamp} - ${event.type} Event: (${x}, ${y}) - Username/Email: ${usernameOrEmail}`);
}

// Function to log link information when a link is hovered or clicked
function logLinkInfo(event, usernameOrEmail) {
    const link = event.target;

    if (link.tagName === 'A') {
        const linkText = link.textContent;
        const linkURL = link.href;
        const timestamp = getUTCTime();

        // Log link text, URL, and event details to console
        console.log(`${timestamp} - Link Hovered/Clicked - Text: "${linkText}" - URL: ${linkURL} - Username/Email: ${usernameOrEmail}`);
    }
}

const GlobalEventLogger = ({ children }) => {
    useEffect(() => {
        const handleMouseDown = (event) => {
            handleMouseEvent(event);
        };

        const handleMouseUp = (event) => {
            handleMouseEvent(event);
        };

        const handleClick = (event) => {
            handleLinkEvent(event, "click");

        };

        const handleMouseOver = (event) => {
            handleLinkEvent(event, "mouseover");
        };

        const handleMouseMove = (event) => {
            // handleMouseEvent(event);
        };

        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('click', handleClick);
        document.addEventListener('mouseover', handleMouseOver);
        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            // Cleanup event listeners when component unmounts
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('click', handleClick);
            document.removeEventListener('mouseover', handleMouseOver);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return <>{children}</>;
};

export default GlobalEventLogger;
