import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiErrorCircle } from 'react-icons/bi';
import firebaseConfig from './firebase_config';
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import moment from 'moment';
import { DEBT_ACCOUNT_TYPES } from './lib/constants';
import parseSmartDate from './lib/common/parseSmartDate';
import describeFutureDate from './lib/common/describeFutureDate';
import abbreviateNumber from './lib/common/abbreviateNumber';
import getSpecialDate from './lib/common/getSpecialDate';
import getDurationInSeconds from './lib/common/getDurationInSeconds';
import toTitleCase from './lib/common/toTitleCase';
import SignInWithApple from './SignInWithApple';

const PLAID_LOGIN_ERRORS = ['ITEM_LOGIN_REQUIRED', 'PENDING_EXPIRATION'];
const PLAID_DEVELOPMENT_DEPRECATED = ['DEVELOPMENT_DEPRECATED'];

function DebtConsolidate() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [totalDebt, setTotalDebt] = useState(0);
    const [minimumPayments, setMinPayments] = useState(0);
    const [upcomingPayments, setUpcomingPayments] = useState([]);

    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }

    const db = firebase.firestore();
    const currentUser = firebase.auth().currentUser;

    const getAccountsForCurrentUser = async () => {
        if (currentUser) {
            setLoading(true);
            setBankAccounts([]);
            setTotalDebt(0);
            setMinPayments(0);
            setUpcomingPayments([]);

            const token = await currentUser.getIdToken();

            fetch(process.env.REACT_APP_CANCELLY_API_URL + '/plaid/balance', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then(res => res.json())
                .then((res) => {
                    if (res.accounts.length > 0) {
                        let debt = 0;
                        const accounts = [];
                        const _upcomingPayments = [];
                        res.accounts.map(a => {
                            if (DEBT_ACCOUNT_TYPES.includes(toTitleCase(a.type))) {
                                console.log(a);
                                debt += a.balances.current;
                                accounts.push(a);
                                _upcomingPayments.push({
                                    id: a.id,
                                    title: a.official_name ?? a.name,
                                    //icon: 'https://icon.cancelly.ca/icon?size=80..120..200&url=' + a.url,
                                    url: a.url,
                                    future_date: moment().add(2, 'days'),
                                });
                            }
                        });
                        setBankAccounts(accounts);
                        setTotalDebt(debt);
                        //setMinPayments(0);
                        setUpcomingPayments(_upcomingPayments);
                    } else {
                        setBankAccounts([]);
                        setTotalDebt(0);
                        setMinPayments(0);
                        setUpcomingPayments([]);
                    }

                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Error fetching documents:', err);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        getAccountsForCurrentUser();
    }, []);

    const payNow = (e, upcomingPayment) => {
        alert('Coming soon!');
    };

    return (
        <div className="text-white w-[600px] lg:w-full m-auto">
            {loading
                ? <img className="self-center inline" src="loader.gif" alt="Loading..." width="100" />
                : <>
                    {/* <h2 className="text-xl mt-4 px-4 font-bold select-none">Next review scheduled on <br />{getSpecialDate()}</h2> */}
                    <div className="text-left mt-4 ml-8 mr-8 mb-6 bg-white text-black rounded">
                        <h3 className="text-xl font-bold border-b-2 p-2 select-none">Borrowing Accounts</h3>
                        {bankAccounts.length > 0 ? <div>
                            <ol className="py-2 px-4 list-decimal ml-4">
                                {bankAccounts.map(account => {
                                    return <li key={account.id} className={(PLAID_LOGIN_ERRORS.includes(account.status) || PLAID_DEVELOPMENT_DEPRECATED.includes(account.status) || !account.hasOwnProperty('item_id')) ? "mb-2 text-red-500 font-bold" : "mb-2 text-blue-600 font-bold"}>
                                        {PLAID_LOGIN_ERRORS.includes(account.status) ?
                                            <button data-item-id={account?.item_id}
                                                data-item-name={account.official_name ?? account.name}
                                                className="ml-2 flex flex-row m-0 p-0 leading-0"
                                            //onClick={fixBank}
                                            >
                                                <span>{account.name}</span>
                                                <><BiErrorCircle className="ml-4 mt-1" />
                                                    <span className="ml-1">Connection broken, click here to fix</span></>
                                            </button> :
                                            <button data-item-id={account.item_id}
                                                data-item-name={account.name}
                                                className="ml-2 flex flex-row m-0 p-0 leading-0"
                                                //onClick={account.type === 'cancelly_extension' ? () => refreshManual(account) : disconnectBank}
                                                title={account.type === 'cancelly_extension' ? "Click to bring fresh data" : "Click to disconnect bank"}>
                                                <p className="text-left">
                                                    {account.name} {account.type === 'cancelly_extension' && <span className="text-sm">- Updated {moment.duration(moment(account?.latest_ts.value).diff(moment())).humanize(true)}</span>}
                                                    {PLAID_DEVELOPMENT_DEPRECATED.includes(account.status) && ' (Old Version, Not Supported Anymore, Please disconnect and reconnect)'}
                                                </p>
                                            </button>}
                                    </li>;
                                })}
                            </ol>
                            <div className="flex ml-4 pb-2">
                                <button onClick={() => navigate("/connect")} className="bg-black text-white p-2 hover:text-slate-400">Connect Bank Account</button>
                                <SignInWithApple buttonText="Connect Apple Account"></SignInWithApple>
                            </div>
                        </div> : <div className="px-4 py-2">
                            <p>No bank accounts found</p>
                            <div className="flex">
                                <button onClick={() => navigate("/connect")} className="bg-black text-white p-2 mt-2 hover:text-slate-400">Connect Bank Account</button>
                                <SignInWithApple buttonText="Connect Apple Account"></SignInWithApple>
                            </div>
                        </div>
                        }
                    </div>
                    <div className="text-left mt-8 ml-8 mr-8 mb-6  text-black rounded">
                        <div className="flex">
                            <button className="w-1/2" onMouseUp={() => navigate("/debt-consolidate")} alt={totalDebt}>
                                <div className="flex flex-col mr-2 p-2 bg-white rounded-2xl h-48 opacity-95 items-center text-center">
                                    <p className="text-2xl xs:text-lg relative z-0 select-none break-all">Total Debt</p>
                                    <p className="text-7xl m-auto relative z-0 select-none"><span className="text-6xl">$</span>{abbreviateNumber(totalDebt)}</p>
                                </div>
                            </button>
                            <div className="w-1/2">
                                <div className="flex flex-col mr-2 p-2 bg-white rounded-2xl h-48 opacity-95 items-center text-center">
                                    <p className="text-2xl xs:text-lg relative z-0 select-none break-all">Monthly Payments</p>
                                    <p className="text-7xl m-auto relative z-0 select-none break-all"><span className="text-6xl">$</span>{abbreviateNumber(minimumPayments)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-left mt-8 ml-8 mr-8 mb-6 bg-white text-black rounded">
                        <h3 className="text-xl font-bold border-b-2 p-2 select-none">Upcoming Payments</h3>
                        {upcomingPayments.length > 0 ?
                            <ul className="py-2 px-4">
                                {upcomingPayments.map(upcomingPayment => {
                                    return <li key={upcomingPayment.id} className="mb-4">
                                        <a href={upcomingPayment.url} target="_blank" className="hover:text-[#523bff]">
                                            {upcomingPayment.icon && <img alt={upcomingPayment.title} src={upcomingPayment.icon} className="inline w-4 mr-2" />}
                                            {upcomingPayment.title} ({describeFutureDate(upcomingPayment.future_date)})
                                        </a>
                                        <button className="bg-black text-white px-1 text-xs" onClick={(e) => { payNow(e, upcomingPayment) }}>
                                            Pay Now
                                        </button>
                                    </li>;
                                })}
                            </ul>
                            :
                            <div className="px-4 py-2">
                                <b>Improve your experience by adding Cancelly to your Chrome.</b>
                                <ul style={{ listStyleType: 'circle', marginLeft: '20px' }}>
                                    <li>Add web pages to remind you to cancel your trials.</li>
                                    <li>It collects your website history to keep track of your spending.</li>
                                    <li>Manually add unsupported institutions data: Koho, MBNA Canada, etc</li>
                                </ul>
                                <button onClick={() => window.location = "https://chrome.google.com/webstore/detail/cancelly/bdehkmgfjppeagefkipigfmlnginebkd"} className="bg-black text-white p-2 mt-2 hover:text-slate-400 select-none">Add to Chrome</button>
                            </div>}
                    </div>
                </>}
        </div>
    );
}

export default DebtConsolidate;
