import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Papa from 'papaparse';

import './FileUploader.css';

const FileUploader = (props) => {
    const [files, setFiles] = useState([]);
    const [data, setData] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [validData, setValidData] = useState(false);
    const [columns, setColumns] = useState(null);

    const handleFileChange = (event) => {
        const uploadedFiles = event.target.files;
        setFiles([...files, ...uploadedFiles]);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation(); // Add this line to stop event propagation
        const droppedFiles = event.dataTransfer.files;
        setFiles([...files, ...droppedFiles]);
        processFiles(event);
        setIsHovered(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsHovered(true);
    };

    const handleDragLeave = () => {
        setIsHovered(false);
    };

    const processFiles = (event) => {
        event.preventDefault()
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const contents = e.target.result;
                // Process the contents of the CSV file, e.g., parse as CSV data
                // Here, we'll assume the CSV has headers and use the Papa Parse library for parsing
                const parsedData = Papa.parse(contents, { header: true }).data;

                const columnsNeed = ['Date', 'Description', 'Amount'];
                const allKeys = Object.keys(parsedData[0]);

                const hasAllKeys = columnsNeed.every(item => allKeys.includes(item));
                const hasAllKeysLowerCase = columnsNeed.every(item => allKeys.includes(item.toLowerCase()));
                if (hasAllKeys) {
                    setValidData(true);

                    setColumns(allKeys.map(key => ({
                        name: key,
                        selector: key,
                    })));

                    setData(parsedData);
                } else if (hasAllKeysLowerCase) {
                    setValidData(true);

                    setColumns(allKeys.map(key => ({
                        name: key.toLowerCase(),
                        selector: key.toLowerCase(),
                    })));

                    setData(parsedData);
                }
                else {
                    setValidData(false);
                    setColumns(columnsNeed);
                    setData([columnsNeed.map(col => ({ col: '' }))]);
                }
            };
            reader.readAsText(file);
        });
    };

    const uploadToFirebase = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData();
        formData.append('email', props.email);
        formData.append('file', files[0]); // Assuming only one file is selected

        fetch(process.env.REACT_APP_CANCELLY_API_URL + '/upload', {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
            },
            body: formData,
        })
            .then((response) => {
                console.log('File uploaded successfully');
                props.onFinish(event);
            })
            .catch((error) => {
                console.error('Error uploading file:', error);
                props.onFinish(event);
            });
    };

    return (
        <div>
            {data.length === 0 ? (
                <div>
                    <h3>Upload a valid CSV file</h3>
                    <div id="notes">
                        <b>Notes:</b>
                        <ul>
                            <li>Make sure the file at least has <b>Date, Description and Amount</b> columns</li>
                            <li>Having more columns like <b>Bank Name, Account Type (Checking, Savings, Credit) and Account Number</b> will be better</li>
                            <li><b>Date</b> format MUST BE <b>MM/DD/YYYY</b></li>

                        </ul>
                    </div>
                    <form>
                        <input type="file" multiple onChange={handleFileChange} />
                        <button className="bg-black px-2 py-1 hover:text-slate-500" onClick={processFiles}>Process Files</button>
                    </form>
                    <br />
                    <div
                        id="dropArea"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        style={{
                            width: '300px',
                            height: '200px',
                            border: isHovered ? '2px dashed red' : '1px dashed black',
                            backgroundColor: isHovered ? 'lightgray' : 'transparent',
                        }}
                    >
                        Drop files here
                    </div>
                </div>
            ) : (
                <div id="dataTableContainer">
                    <div className="flex text-white py-2">
                        {data && data.length > 0 && validData ? (<p className="flex flex-none text-xl">Data looks good!</p>) : (<p className="text-left text-lg bg-white p-1 mr-2 text-red-600">Error: Invalid data check if any column is missing in the data <b>(Date, Description, Amount)</b></p>)}
                        <div className="flex-1 right right-0 text-right">
                            <button className="bg-black px-2 py-1 disabled:bg-slate-600 disabled:text-slate-500" disabled={!validData} onClick={uploadToFirebase}>Submit</button>
                        </div>
                    </div>
                    {columns && columns.length > 0 &&
                        <DataTable
                            title="CSV Data"
                            columns={columns}
                            data={data}
                            pagination
                            highlightOnHover
                        />
                    }
                </div>
            )}
        </div>
    );
};

export default FileUploader;
