const moment = require('moment-timezone');

export default function parseSmartDate(created_ts, duration) {
    const startDate = moment(created_ts.seconds * 1000);

    const regex = /(\d+)\s+(day|month|year)s?/i;
    const match = duration.match(regex);

    if (!match) {
        throw new Error('Invalid date format');
    }

    const amount = parseInt(match[1]);
    const unit = match[2].toLowerCase();

    let date;

    switch (unit) {
        case 'day':
            date = startDate.add(amount, 'days');
            break;
        case 'month':
            date = startDate.add(amount, 'months');
            break;
        case 'year':
            date = startDate.add(amount, 'years');
            break;
        default:
            throw new Error('Invalid date unit');
    }

    return date;
}
