import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import HomeScreen from './HomeScreen';
import '../tailwind.css';

function AdminApp() {
    return (
        <Routes>
          <Route path="/" element={<HomeScreen />} />
        </Routes>
    );
}

export default AdminApp;
