function ThanksScreen() {
    // localStorage.removeItem("email");

    return (
        <div className="App-Component component-wrapper">
            <div className="mt-8">
                <h2 className="text-2xl">Thank you for using Cancelly</h2>
                <div className="loader mt-5">Crunching Data</div>
                <p className="mt-5">Please look out for an email from Cancelly.</p>
                <p className="mt-5">Cancelly is in early testing phase right now, we are working hard to help you cut costs and save more money.</p>
            </div>
        </div>
    );
}

export default ThanksScreen;
