import { FaCrown } from 'react-icons/fa';

function PricingScreen() {
    return (
        <div className="App-Component component-wrapper lg:w-full m-auto">
            <section className="relative z-20overflow-hidden bg-white text-black pt-5 pb-12 lg:pt-[80px] lg:pb-[90px]">
                <div className="">
                    <div className="w-full px-4">
                        <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-15">
                            <div className="flex justify-center text-6xl">
                                <FaCrown />
                            </div>
                            <span className="block mb-2 text-2xl font-semibold text-primary pb-3 lg:pb-[40px]">
                                Cancelly Pro
                            </span>
                            <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
                                Pricing Plan
                            </h2>
                            <p className="text-base text-body-color">
                                100% Satisfaction with 30-day Money Back Guaranteed
                            </p>
                        </div>
                    </div>
                    <div className="justify-center">
                        <div className="flex lg:block">
                            <PricingCard
                                type="Never"
                                price="$0"
                                subscription="month"
                                description="Free Basics For All"
                                description2="Try it for free"
                                savings="100%"
                                original="$59.88"
                                buttonText="Get Chrome Extension"
                                buttonLink="https://chromewebstore.google.com/detail/cancelly/bdehkmgfjppeagefkipigfmlnginebkd"
                                buttonTarget='_blank'
                                active
                            >
                                <List>Free Chrome Extension & Trial Tracker</List>
                                <List>Subscriptions & Trials Count</List>
                                <List>Bank Fees & Interest Tracker</List>
                                <List>Free Bank Connects</List>
                            </PricingCard>
                            <PricingCard
                                type="Yearly"
                                price="$4.99"
                                subscription="month"
                                description="Save 67% with our most popular plan"
                                description2="Only $59.88/year"
                                savings="67%"
                                original="$119.88"
                                buttonText="Choose Annual"
                                buttonLink="https://buy.stripe.com/14kg2G1hAcYK73aaEK"
                                buttonTarget='_self'
                                active
                            >
                                <List>Review Subscriptions & Trials</List>
                                <List>Bank Fees & Interest Tracker</List>
                                <List>Debt Consolidation (Included)</List>
                                <List>Automated Payroll (Included)</List>
                                <List>Premium Support</List>
                                <List>Cancel Anytime</List>
                            </PricingCard>
                            <PricingCard
                                type="Monthly"
                                price="$9.99"
                                subscription="month"
                                description="Perfect for trying out our service"
                                description2=""
                                savings="0%"
                                original="$119.88"
                                buttonText="Choose Monthly"
                                buttonLink="https://buy.stripe.com/6oE2bQ1hAcYKgDK6ov"
                                buttonTarget='_self'
                            >
                                <List>Review Subscriptions & Trials</List>
                                <List>Bank Fees & Interest</List>
                                <List>Debt Consolidation (Included)</List>
                                <List>Automated Payroll (Included)</List>
                                <List>Tax Filing (Included)</List>
                                <List>Premium Support</List>
                                <List>Cancel Anytime</List>
                            </PricingCard>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PricingScreen;

const PricingCard = ({
    children,
    description,
    description2,
    original,
    price,
    type,
    subscription,
    buttonText,
    buttonLink,
    buttonTarget,
    active,
}) => {
    return (
        <>
            <div className="w-full px-4">
                <div className="relative z-10 px-8 py-10 mb-10 overflow-hidden bg-white text-black border rounded-xl border-primary border-opacity-20 shadow-pricing sm:p-12 lg:py-4 lg:px-6 xl:p-2">
                    <span className="block mb-4 text-lg font-semibold text-primary">
                        {type}
                    </span>
                    <h2 className="mb-5 text-[42px] font-bold text-dark">
                        {price}
                        <span className="text-base font-medium text-body-color">
                            / {subscription}
                        </span>
                    </h2>
                    <p className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color text-lg leading-relaxed">
                        {description} <br />
                        {description2} {active ? <span className="line-through">{original}</span> : <span>Yearly {original}</span>}
                    </p>
                    <ul className="mb-7">
                        <p className="mb-1 text-base leading-loose text-body-color">
                            {children}
                        </p>
                    </ul>
                    <a
                        href={buttonLink}
                        target={buttonTarget}
                        className={` ${active
                            ? ` w-full block text-base font-semibold text-black bg-primary border border-primary rounded-md text-center p-4 hover:bg-opacity-90 transition`
                            : `block w-full rounded-md border  border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-primary hover:bg-primary hover:text-slate-300`
                            } `}
                    >
                        {buttonText}
                    </a>
                    <div>
                        <span className="absolute right-0 top-7 z-[-1]">
                            <svg
                                width={77}
                                height={172}
                                viewBox="0 0 77 172"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx={86} cy={86} r={86} fill="url(#paint0_linear)" />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear"
                                        x1={86}
                                        y1={0}
                                        x2={86}
                                        y2={172}
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                                        <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </span>
                        <span className="absolute right-4 top-4 z-[-1]">
                            <svg
                                width={41}
                                height={89}
                                viewBox="0 0 41 89"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="38.9138"
                                    cy="87.4849"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 87.4849)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="74.9871"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 74.9871)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="62.4892"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 62.4892)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="38.3457"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 38.3457)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="13.634"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 13.634)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="50.2754"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 50.2754)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="26.1319"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 26.1319)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="38.9138"
                                    cy="1.42021"
                                    r="1.42021"
                                    transform="rotate(180 38.9138 1.42021)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="87.4849"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 87.4849)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="74.9871"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 74.9871)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="62.4892"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 62.4892)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="38.3457"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 38.3457)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="13.634"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 13.634)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="50.2754"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 50.2754)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="26.1319"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 26.1319)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="26.4157"
                                    cy="1.4202"
                                    r="1.42021"
                                    transform="rotate(180 26.4157 1.4202)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="87.4849"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 87.4849)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="74.9871"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 74.9871)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="62.4892"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 62.4892)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="38.3457"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 38.3457)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="13.634"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 13.634)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="50.2754"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 50.2754)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="26.1319"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 26.1319)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="13.9177"
                                    cy="1.42019"
                                    r="1.42021"
                                    transform="rotate(180 13.9177 1.42019)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="87.4849"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 87.4849)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="74.9871"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 74.9871)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="62.4892"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 62.4892)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="38.3457"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 38.3457)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="13.634"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 13.634)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="50.2754"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 50.2754)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="26.1319"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 26.1319)"
                                    fill="#3056D3"
                                />
                                <circle
                                    cx="1.41963"
                                    cy="1.4202"
                                    r="1.42021"
                                    transform="rotate(180 1.41963 1.4202)"
                                    fill="#3056D3"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </div >
        </>
    );
};

const List = ({ children }) => {
    return (
        <>
            <p className="mb-1 text-base leading-loose text-body-color">{children}</p>
        </>
    );
};
