const firebaseConfig = {
    apiKey: "AIzaSyDiUeDzUScJcEHKOBjBet9ODLa9czL6csM",
    authDomain: "cancelly.ca",
    projectId: "cancelly-ff7ce",
    storageBucket: "cancelly-ff7ce.appspot.com",
    messagingSenderId: "329611843796",
    appId: "1:329611843796:web:b34d63f1caba87d53cc341",
    measurementId: "G-XREPEF34DM"
};

export default firebaseConfig;
