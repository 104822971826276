import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaLock } from 'react-icons/fa';
import { FaBars, FaCrown } from 'react-icons/fa';
import moment from 'moment';
import firebaseConfig from './firebase_config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { ADMIN_USERS } from './lib/constants';

const FAKE_SUBS = [
    {
        name: "Facebook",
        domain: "facebook.com",
        amount: 100,
        date: {
            value: "2023-12-01"
        }
    },
    {
        name: "Google",
        domain: "google.com",
        amount: 8,
        date: {
            value: "2023-12-01"
        }
    },
    {
        name: "Microsoft",
        domain: "microsoft.com",
        amount: 50,
        date: {
            value: "2023-12-01"
        }
    }
];

function SubscriptionScreen() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [subs, setSubs] = useState([]);
    const [subsCount, setSubsCount] = useState();
    const [subsSum, setSubsSum] = useState();
    const [blur, setBlur] = useState(true);

    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }

    // const db = firebase.firestore();
    const currentUser = firebase.auth().currentUser;

    const checkStripeStatus = async () => {
        if (currentUser) {
            const token = await currentUser.getIdToken();

            fetch(process.env.REACT_APP_CANCELLY_API_URL + "/stripe/status", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: currentUser.email })
            })
                .then(res => res.json())
                .then(res => {
                    const isAdmin = window.localStorage.getItem("admin") || false;
                    if (isAdmin || ADMIN_USERS.includes(currentUser.email)) {
                        setBlur(false);
                        getSubsForCurrentUser();
                    } else if (res.status === "ok" && res.count > 0) {
                        setBlur(false);
                        getSubsForCurrentUser();
                    } else {
                        setLoading(false);
                        setBlur(true);
                        setSubs(FAKE_SUBS);
                        setSubsCount(0);
                        setSubsSum(0);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setBlur(true);
                    setSubs(FAKE_SUBS);
                    setSubsCount(0);
                    setSubsSum(0);
                });
        }
    };

    const getSubsForCurrentUser = async () => {
        try {
            if (currentUser) {
                const token = await currentUser.getIdToken();

                fetch(process.env.REACT_APP_CANCELLY_API_URL + '/subs/list', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
                    .then(res => res.json())
                    .then((res) => {
                        if (res.hasOwnProperty('error')) {
                            console.log(res.error);
                        }

                        let data = res.subs;
                        let _sum = 0;

                        data = data.map(doc => {
                            _sum += doc.amount;
                            // const newDoc = {
                            //     current_date: moment.utc(doc.created_ts.value * 1000),
                            //     ...doc
                            // };
                            // return newDoc;
                            return doc;
                        });
                        data = data.sort((a, b) => new Date(b.date.value) - new Date(a.date.value));
                        if (data) {
                            setSubs(data);
                            setSubsCount(data.length);
                            setSubsSum(Math.round(_sum, 2));
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        setLoading(false);
                        console.error('Error fetching document count:', err);
                    });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching Firestore data:', error);
        }
    };

    useEffect(() => {
        checkStripeStatus();
    }, []);

    return (
        <div className="text-white w-[600px] lg:w-full m-auto">
            {loading
                ? <img className="self-center inline" src="loader.gif" alt="Loading..." width="100" />
                : subs.length > 0
                    ?
                    <div>
                        {blur && <div
                            style={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                minWidth: '300px',
                                maxWidth: '400px',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white',
                                color: 'black',
                                padding: '20px',
                                borderRadius: '5px',
                                opacity: 100,
                                zIndex: 1,
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                            }}
                        >
                            <h2 className="text-3xl text-black flex flex-col items-center">
                                <p className="mb-2"><FaLock /></p>
                                <p>Only Available for Pro Members</p>
                            </h2>
                            <br />
                            <a href="/pricing" style={{ pointerEvents: 'auto' }} className="text-blue-600 underline hover:no-underline">Get 15 Days Free Trial With Any Subscription</a>
                        </div>}
                        <div className="text-left mb-6 text-black rounded">
                            <div className="flex">
                                <div className="w-1/2 mr-1" style={{
                                    filter: blur ? 'blur(8px)' : 'none',
                                    pointerEvents: blur ? 'none' : 'auto'
                                }}>
                                    <div className="flex flex-col mr-2 p-2 bg-white rounded-2xl h-48 opacity-95 items-center text-center">
                                        <p className="text-2xl relative z-0 select-none break-all">Count</p>
                                        <p className="text-5xl m-auto relative z-0 select-none">{subsCount}</p>
                                    </div>
                                </div>
                                <div className="w-1/2 ml-1" style={{
                                    filter: blur ? 'blur(8px)' : 'none',
                                    pointerEvents: blur ? 'none' : 'auto'
                                }}>
                                    <div className="flex flex-col mr-2 p-2 bg-white rounded-2xl h-48 opacity-95 items-center text-center">
                                        <p className="text-2xl relative z-0 select-none break-all">Total Spend</p>
                                        <p className="text-5xl m-auto relative z-0 select-none break-all">${subsSum}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="mb-5 text-center">
                            <button className="bg-gray-100 text-black p-2 hover:drop-shadow text-lg rounded">Toggle Categories <FaCrown className="inline w-6 h-6 p-0.5 rounded bg-[#FFD700]" /></button>
                            <button className="ml-4 bg-gray-100 text-black p-2 hover:drop-shadow text-lg rounded">Toggle Graphs <FaCrown className="inline w-6 h-6 p-0.5 rounded bg-[#FFD700]" /></button>
                        </div> */}
                        <ul className="text-left bg-white text-black rounded mb-2">
                            {subs.map(sub => <li key={sub.name} className="p-4 hover:bg-slate-200 hover:first:rounded-t hover:last:rounded-b"
                                style={{
                                    filter: blur ? 'blur(8px)' : 'none',
                                    pointerEvents: blur ? 'none' : 'auto'
                                }}>
                                <div className="flex items-center text-lg">
                                    <div className="w-18 min-w-18">
                                        <img alt="company logo" src={"https://icon.cancelly.ca/icon?size=80..120..200&url=" + sub.domain} width="60px" />
                                    </div>
                                    <div className="flex-1 ml-2">
                                        <p className="font-bold break-all">{sub.name}</p>
                                        <p className="text-sm break-all">{moment(sub.date.value, "YYYY-MM-DD").format("DD MMM YYYY")}</p>
                                    </div>
                                    <div className="flex-2">
                                        <div className="flex flex-row items-center">
                                            <p className="ml-auto break-all mr-1">${sub.amount}</p>
                                            <button className="flex-none ml-auto min-w-24 w-24 min-h-16 h-16 bg-red-600 text-white"
                                                onClick={() => alert("Coming soon! :)")}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                    : <p>Good job! No subscriptions found.</p>}
        </div>
    );
}

export default SubscriptionScreen;
