import React, { useState, useEffect } from 'react';

const SignInWithApple = (props) => {
    const [signInButtonText, setSignInButtonText] = useState(props.buttonText || 'Sign In with Apple');
    const [buttonShown, setShowButton] = useState(false);

    const handleKeyDown = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
            event.preventDefault();
            setShowButton(!buttonShown);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleAppleLogin = async (authorization) => {
        try {
            const code = authorization.code;

            console.log(authorization);

            fetch(process.env.REACT_APP_CANCELLY_API_URL + '/auth/apple', {
                method: 'POST',
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify({ code }),
            })
                .then((res) => res.json())
                .then((res) => {
                    console.log(res.data);
                })
                .catch((error) => {
                    console.error(error);
                });

        } catch (error) {
            console.error(error);
        }
    };

    const signInWithApple = () => {
        window.AppleID.auth.signIn().then(({ authorization }) => {
            handleAppleLogin(authorization);
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="ml-2">
            {buttonShown && <button onClick={signInWithApple} className="bg-black text-white p-2 hover:text-slate-400">{signInButtonText}</button>}
        </div>
    );
};

export default SignInWithApple;
