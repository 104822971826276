import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FileUploader from './FileUploader';

function FileUpload(props) {
    const navigate = useNavigate();
    // const email = props.location.state.email;
    const [email, setEmail] = useState();


    const finishFileUploader = (event) => {
        event.preventDefault();
        navigate('/thanks');
    };

    return (
        <div className="App-Component component-wrapper">
            <FileUploader email={email} onFinish={finishFileUploader} />
        </div>
    );
}

export default FileUpload;
