export default function getDurationInSeconds(durationString) {
    const durationParts = durationString.split(' ');
    const value = parseInt(durationParts[0]);
    const unit = durationParts[1].toLowerCase();

    let multiplier = 1;

    switch (unit) {
        case 'second':
        case 'seconds':
            multiplier = 1;
            break;
        case 'minute':
        case 'minutes':
            multiplier = 60;
            break;
        case 'hour':
        case 'hours':
            multiplier = 60 * 60;
            break;
        case 'day':
        case 'days':
            multiplier = 24 * 60 * 60;
            break;
        case 'month':
        case 'months':
            multiplier = 30 * 24 * 60 * 60; // Approximate
            break;
        case 'year':
        case 'years':
            multiplier = 365 * 24 * 60 * 60; // Approximate
            break;
        default:
            console.warn(`Unknown duration unit: ${unit}`);
            break;
    }

    return value * multiplier;
}
