import React, { useState } from "react";
//import { useNavigate } from "react-router-dom";
//import { FaLock } from 'react-icons/fa';
//import moment from 'moment';
import firebaseConfig from './firebase_config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { ADMIN_USERS } from './lib/constants';

function ManageAccountScreen() {
    //const navigate = useNavigate();
    const [showCancelButton, setShowCancelButton] = useState(false);

    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }

    // const db = firebase.firestore();
    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
        (async () => {
            const token = await currentUser.getIdToken();

            fetch(process.env.REACT_APP_CANCELLY_API_URL + "/stripe/status", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: currentUser.email })
            })
                .then(res => res.json())
                .then(res => {
                    if (ADMIN_USERS.includes(currentUser.email)) {
                        setShowCancelButton(false);
                    } else if (res.status === "ok" && res.count > 0) {
                        setShowCancelButton(true);
                    } else {
                        setShowCancelButton(false);
                    }
                })
                .catch(err => {
                    setShowCancelButton(false);
                });
        })();
    }

    return (
        <div className="text-white w-[600px] lg:w-full m-auto lg:m-5">
            <h1 className="text-3xl">Manage Account</h1>
            <div className="mt-10">
                {showCancelButton
                    ? <div>
                        <a className="p-4 bg-red-600 block w-full" href="https://billing.stripe.com/p/login/14keWT3Iu4ui3rW7ss">Cancel PRO</a>
                        <p className="mt-1"><b>Important Note: </b>Use the same email address on the next page which you used when signing up for Cancelly</p>
                    </div>
                    : <div>
                        <button disabled className="p-4 bg-gray-600 block w-full">Cancel PRO</button>
                        <p className="mt-1">No subscriptions found</p>
                    </div>
                }
            </div>
        </div>
    );
}

export default ManageAccountScreen;
